// import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// import { CartContext } from '../../../contexts/cart.context';
import { selectCartItems } from '../../../store/cart/cart.selector';

import Button from '../../button/button.component';
import CartItem from '../cart-item/cart-item.component';

import './cart-dropdown.styles.scss';

const CartDropdown = () => { 
  const cartItems = useSelector(selectCartItems);  
  const navigate = useNavigate();

  const onClickCheckout = () =>{
    navigate('/cart')
  }

  return (
    <div className='cart-dropdown-container'>
      <div className='cart-items'>
        {cartItems.length ? (
          cartItems.map((cartItem) => (
            <CartItem key={cartItem.id} cartItem={cartItem} />
          ))
        ) : (
          <span className='empty-message'>Your cart is empty</span>
        )}
      </div>
      <Button onClick={onClickCheckout}>GO TO CHECKOUT</Button>
    </div>
  );
};

export default CartDropdown;